import { SyntheticEvent, useCallback, useRef } from 'react';
import { Button } from 'primereact/button';
import { NavigateAction } from 'react-big-calendar';
import { OverlayPanel } from 'primereact/overlaypanel';

import { CalendarPicker } from '@/components';
import { DateTime } from 'luxon';
import { DailyNotes, Room, VisitTypeTemplates } from '@/@types';
import { Permission, useAuthContext } from '@/contexts/authContext';
import { DateTimeHelper } from '@/helpers';
import MobileDetect from 'mobile-detect';
import EditIcon from '@/assets/icons/edit.svg';
import { env } from '@/utils/constants';
import {
  defaultDailyNoteTitle,
  standardDailyNoteTitles,
} from '@/components/DailyNotesDrawer/DailyNotesDrawer';
import { CreateAppointmentFromTemplate } from '@/features/AppointmentActions/components';

type ToolbarProps = {
  date: Date;
  onNavigate: (navigate: NavigateAction, date?: Date) => void;
  hasCalendarPicker?: boolean;
  hasDailyNotes?: boolean;
  dateComponent?: React.ReactNode;

  onToggleFiltersClick?: VoidFunction;
  dailyNotes?: DailyNotes[];
  roomsOptionsList?: Room[];
  suiteRooms?: number[];
  onDailyNotesClick?: (index: number, dailyNote?: DailyNotes) => void;
  onNewAppointmentClick?: VoidFunction;
  onAppointmentCreateByTemplateSubmit?: VoidFunction;
  onCalendarViewSettingsClick?: VoidFunction;
  onAppointmentsDeletedHistoryClick?: VoidFunction;
  outOfOfficeProviders?: string;
  visitTypeTemplates: VisitTypeTemplates[];
};

export const Toolbar = ({
  date,
  onNavigate,
  hasCalendarPicker = true,
  hasDailyNotes = true,
  dateComponent,
  dailyNotes = [],
  onDailyNotesClick,
  onCalendarViewSettingsClick,
  onAppointmentsDeletedHistoryClick,
  onNewAppointmentClick,
  onAppointmentCreateByTemplateSubmit,
  roomsOptionsList,
  suiteRooms,
  onToggleFiltersClick,
  outOfOfficeProviders,
  visitTypeTemplates,
}: ToolbarProps) => {
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  const isMobile = mobileDetect.mobile();
  const { hasPermission } = useAuthContext();
  const appointmentCreationOverlayPanelRef = useRef<OverlayPanel>(null);

  const onAppointmentFromTemplateClick = useCallback((e: SyntheticEvent) => {
    if (appointmentCreationOverlayPanelRef.current) {
      appointmentCreationOverlayPanelRef.current.toggle(e);
    }
  }, []);

  const handleAppointmentsCreationByTemplateSubmit = useCallback(() => {
    appointmentCreationOverlayPanelRef.current?.hide();
    onAppointmentCreateByTemplateSubmit && onAppointmentCreateByTemplateSubmit();
  }, [onAppointmentCreateByTemplateSubmit]);

  return (
    <div className={`w-full ${isMobile ? 'landscape:hidden' : ''}`}>
      <div className='w-full grid grid-cols-1 lg:grid-cols-3 mb-3 lg:gap-2'>
        {!isMobile && (
          <div className='flex justify-center lg:justify-start items-center'>
            {onToggleFiltersClick && (
              <Button
                className='mr-2 text-product-grey-400 font-normal'
                label='Filters'
                size='small'
                text
                onClick={onToggleFiltersClick}
              />
            )}
          </div>
        )}

        <div className='flex items-center justify-center'>
          <div className='flex justify-center items-center gap-2'>
            <Button
              icon='pi pi-chevron-left'
              aria-label='Previous day'
              rounded
              outlined
              className='w-9 h-9 p-2 border-product-sand-200 text-[16px]'
              onClick={() => onNavigate('PREV')}
            />
            {hasCalendarPicker ? (
              <CalendarPicker
                date={date}
                onChange={(newDate: Date) => {
                  const dateToUse = new Date(
                    DateTimeHelper.returnOnlyDateYYYYmmDD(newDate) + 'T04:04:00-04:00'
                  );
                  onNavigate('DATE', dateToUse);
                }}
              />
            ) : (
              dateComponent
            )}

            <Button
              icon='pi pi-chevron-right'
              aria-label='Next day'
              rounded
              outlined
              className='w-9 h-9 p-2 border-product-sand-200 text-[16px]'
              onClick={() => onNavigate('NEXT')}
            />
          </div>
        </div>

        {!isMobile && (
          <div className='flex flex-col lg:flex-row items-center justify-center lg:justify-between'>
            <div className='flex items-center justify-start'>
              <Button
                text
                size='small'
                tooltipOptions={{ position: 'bottom' }}
                onClick={() => {
                  const today = DateTime.now().toJSDate();
                  onNavigate('DATE', today);
                }}
                className='text-product-grey-400 text-sm text-center p-2'
              >
                Today
              </Button>

              {hasDailyNotes && onDailyNotesClick && (
                <Button
                  rounded
                  text
                  aria-label='Daily notes'
                  tooltip='Daily notes'
                  tooltipOptions={{ position: 'bottom' }}
                  onClick={() => onDailyNotesClick(dailyNotes?.length)}
                  className='text-product-grey-400 text-sm text-center p-2'
                >
                  <i className='material-icons-outlined h-5 w-5 text-[20px]'>comment</i>
                </Button>
              )}

              {onCalendarViewSettingsClick && (
                <Button
                  rounded
                  text
                  aria-label='Toggles'
                  tooltip='Calendar settings'
                  tooltipOptions={{ position: 'bottom' }}
                  onClick={onCalendarViewSettingsClick}
                  className='text-product-grey-400 p-2'
                >
                  <i className='material-icons-outlined h-5 w-5 text-[20px]'>settings</i>
                </Button>
              )}

              {onAppointmentsDeletedHistoryClick && (
                <Button
                  rounded
                  text
                  aria-label='Toggles'
                  tooltip='Deleted appointments history'
                  tooltipOptions={{ position: 'bottom' }}
                  onClick={onAppointmentsDeletedHistoryClick}
                  className='text-product-grey-400'
                >
                  <i className='material-icons-outlined h-5 w-5 text-[20px]'>delete</i>
                </Button>
              )}
            </div>
            <div className='flex items-center h-full py-2'>
              {onNewAppointmentClick && (
                <>
                  {hasPermission(Permission['CREATE_APPOINTMENT:TEMPLATE']) ? (
                    <>
                      <Button
                        label='New Appointment'
                        size='small'
                        className='rounded-s-[42px] rounded-e-none py-[6px] pl-3 pr-1 text-xs min-h-9 h-full'
                        onClick={onNewAppointmentClick}
                        disabled={!hasPermission(Permission.CREATE_APPOINTMENT)}
                      />

                      <Button
                        size='small'
                        className='rounded-s-none rounded-e-[42px] border-l-0 py-[6px] px-1 min-h-9 h-full'
                        onClick={onAppointmentFromTemplateClick}
                        disabled={!hasPermission(Permission.CREATE_APPOINTMENT)}
                      >
                        <i className='material-icons-outlined h-5 w-5 text-[20px]'>
                          keyboard_arrow_down
                        </i>
                      </Button>
                      <OverlayPanel
                        ref={appointmentCreationOverlayPanelRef}
                        className='create-new-appointment-overlay'
                      >
                        <CreateAppointmentFromTemplate
                          roomsOptionsList={roomsOptionsList || []}
                          suiteRooms={suiteRooms || []}
                          handleCreateNew={onNewAppointmentClick}
                          onSave={handleAppointmentsCreationByTemplateSubmit}
                          currentDate={DateTime.fromJSDate(date).toFormat('MM/dd/yyyy')}
                          visitTypeTemplates={visitTypeTemplates || []}
                        />
                      </OverlayPanel>
                    </>
                  ) : (
                    <Button
                      label='New Appointment'
                      icon='pi pi-plus'
                      size='small'
                      className='rounded-[42px] h-full text-xs'
                      onClick={onNewAppointmentClick}
                      disabled={!hasPermission(Permission.CREATE_APPOINTMENT)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_MULTIPLE_DAILY_NOTES &&
        !!dailyNotes?.length &&
        onDailyNotesClick && (
          <div className='flex gap-2 mt-4 mb-4'>
            <div className='overflow-x-auto flex gap-2'>
              {dailyNotes?.map((dailyNote, index) => (
                <div
                  key={`daily-note-${index}`}
                  className='overflow-x-auto min-w-[300px] w-[400px] max-h-[200px] bg-[#F8F7F5] py-2 pl-3 pr-2 flex items-start rounded-[4px] min-h-12'
                >
                  <div className='flex-1 flex flex-col items-start text-sm text-[#3C4E3D]'>
                    <span className='font-bold block text-[#3C4E3D] leading-4'>
                      {dailyNote.title || standardDailyNoteTitles[index] || defaultDailyNoteTitle}
                    </span>

                    {dailyNote && (
                      <p
                        className='text-[#3C4E3D] font-medium leading-4'
                        dangerouslySetInnerHTML={{ __html: dailyNote.html }}
                      />
                    )}
                  </div>

                  <img
                    src={EditIcon}
                    alt='Edit daily notes'
                    className='cursor-pointer'
                    onClick={() => onDailyNotesClick(dailyNotes.length, dailyNote)}
                  />
                </div>
              ))}
            </div>

            <div
              className='flex justify-center items-center cursor-pointer min-w-[38px] w-[38px] min-h-[48px] rounded bg-[#F8F7F5]'
              onClick={() => onDailyNotesClick(dailyNotes?.length)}
            >
              <i className='pi pi-plus w-[14px] h-[16px]' />
            </div>
          </div>
        )}

      {!env.APP_FEATURE_FLAGS.IS_TO_ENABLE_MULTIPLE_DAILY_NOTES &&
        onDailyNotesClick &&
        !!dailyNotes?.length && (
          <div className='w-full bg-gray-100 py-2 px-3 mt-4 flex items-center rounded-[10px] mb-4'>
            <div className='flex-1 flex flex-col items-start text-xs lg:text-sm'>
              <span className='font-bold block text-off-black'>Daily notes</span>

              {dailyNotes && (
                <p
                  className='text-off-black'
                  dangerouslySetInnerHTML={{ __html: dailyNotes[0]?.html }}
                />
              )}
            </div>
            <Button
              icon='pi pi-pencil'
              size='small'
              text
              rounded
              onClick={() => onDailyNotesClick(dailyNotes?.length, dailyNotes[0])}
              className='w-[50px]'
              tooltip='Edit daily notes'
              tooltipOptions={{ position: 'bottom' }}
            />
          </div>
        )}

      {outOfOfficeProviders && (
        <div className='w-full min-h-8 px-5 py-2 bg-neutral-100 border-l border-r border-t border-gray-200 justify-center items-center gap-3 inline-flex'>
          <div className="text-center text-sm text-neutral-700 text-[11px] font-bold font-['Noto Sans'] leading-none">
            OOO: {outOfOfficeProviders}
          </div>
        </div>
      )}
    </div>
  );
};
