import { ReactNode, useEffect, useCallback } from 'react';

import { useLocation } from '@/contexts';
import { Region } from '@/@types';
import { Navbar } from '../Navbar';

type SchedulerLayoutProps = {
  headerComponent?: ReactNode;
  isLocationRequired?: boolean;
  currentDate?: string;
  onLocationChangeCallback?: (regionId: number) => void;
  setShowFilters?: (show: boolean) => void;
  setShowAppointmentsDeletedHistory?: (show: boolean) => void;
  setShowCalendarSettings?: (show: boolean) => void;
} & React.PropsWithChildren;

export const SchedulerLayout = ({
  children,
  headerComponent,
  isLocationRequired,
  currentDate,
  onLocationChangeCallback,
  setShowFilters,
  setShowAppointmentsDeletedHistory,
  setShowCalendarSettings,
}: SchedulerLayoutProps) => {
  const {
    findAllLocations,
    locations: locationOptionsList,
    selectedRegion,
    changeLocation,
    setSelectedRegion,
  } = useLocation();

  useEffect(() => {
    findAllLocations();
  }, [findAllLocations]);

  const handleLocationChange = useCallback(
    (regionId: number) => {
      changeLocation(regionId);
      onLocationChangeCallback && onLocationChangeCallback(regionId);
    },
    [changeLocation, onLocationChangeCallback]
  );

  return (
    <>
      {isLocationRequired ? (
        <>
          {!selectedRegion && locationOptionsList?.length > 0 ? (
            <LocationSelectionOverlay
              locations={locationOptionsList}
              setSelectedRegion={setSelectedRegion}
            />
          ) : (
            <>
              <Navbar
                locationOptions={locationOptionsList}
                regionSelected={selectedRegion?.id}
                changeRegion={handleLocationChange}
                headerComponent={headerComponent}
                shouldShowLocationSelection={true}
                currentDate={currentDate}
                setShowFilters={setShowFilters}
                setShowAppointmentsDeletedHistory={setShowAppointmentsDeletedHistory}
                setShowCalendarSettings={setShowCalendarSettings}
              />
              {children}
            </>
          )}
        </>
      ) : (
        <>
          <Navbar
            locationOptions={locationOptionsList}
            regionSelected={selectedRegion?.id}
            changeRegion={handleLocationChange}
            headerComponent={headerComponent}
            shouldShowLocationSelection={false}
            currentDate={currentDate}
            setShowFilters={setShowFilters}
            setShowAppointmentsDeletedHistory={setShowAppointmentsDeletedHistory}
            setShowCalendarSettings={setShowCalendarSettings}
          />
          {children}
        </>
      )}
    </>
  );
};

type LocationSelectionOverlayProps = {
  locations: Region[];
  setSelectedRegion: (location: Region | null) => void;
};

const LocationSelectionOverlay = ({
  locations,
  setSelectedRegion,
}: LocationSelectionOverlayProps) => {
  return (
    <div className='text-center content-center h-screen'>
      <h2 className='text-2xl font-bold mb-4'>Please choose a practice location:</h2>
      <div className='space-x-4'>
        {locations.map((locationOption) => (
          <button
            key={locationOption.id}
            className='min-w-[170px] px-10 py-12
       bg-primary-200 text-white rounded hover:bg-kelp'
            onClick={() => setSelectedRegion(locationOption)}
          >
            {locationOption.name}
          </button>
        ))}
      </div>
    </div>
  );
};
