import { Outlet } from 'react-router-dom';
import { LocationContext } from '.';
import {
  ActionHistoryContext,
  DailyNotesContext,
  ProvidersContext,
  RoomsContext,
  SchedulerViewSettingsContext,
} from '@/hooks';
import { AppointmentTypeContext } from './appointmentTypeContext';
import { AppointmentActionsContext } from '@/features/AppointmentActions';
import { SchedulerSavedViewContext } from '@/features/Calendar/contexts/schedulerSavedViewContext';

export function SchedulerContexts() {
  return (
    <ActionHistoryContext>
      <RoomsContext>
        <ProvidersContext>
          <DailyNotesContext>
            <SchedulerViewSettingsContext>
              <AppointmentTypeContext>
                <LocationContext>
                  <SchedulerSavedViewContext>
                    <AppointmentActionsContext>
                      <Outlet />
                    </AppointmentActionsContext>
                  </SchedulerSavedViewContext>
                </LocationContext>
              </AppointmentTypeContext>
            </SchedulerViewSettingsContext>
          </DailyNotesContext>
        </ProvidersContext>
      </RoomsContext>
    </ActionHistoryContext>
  );
}
