import { useAthenaStatusSocket } from '@/hooks/useAthenaStatusSocket';
import { useCallback, useState } from 'react';

enum ComponentStatus {
  Offline,
  Initial,
  Online,
}

export const AthenaStatus = () => {
  const [status, setStatus] = useState<ComponentStatus>(ComponentStatus.Initial);

  const changeStatus = useCallback(
    (isOnline: boolean) => {
      if (status === ComponentStatus.Initial && isOnline) {
        return;
      }

      if (status === ComponentStatus.Initial && !isOnline) {
        setStatus(ComponentStatus.Offline);
      }

      if (status === ComponentStatus.Offline && isOnline) {
        setStatus(ComponentStatus.Online);
      }

      if (status === ComponentStatus.Online && !isOnline) {
        setStatus(ComponentStatus.Offline);
      }
    },
    [setStatus, status]
  );

  useAthenaStatusSocket(changeStatus);

  return (
    <>
      {status === ComponentStatus.Offline && (
        <div
          className='flex items-center p-4 m-3 mr-3 ml-3 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800'
          role='alert'
        >
          <i className='pi pi-info-circle'></i>
          <div className='ml-2'>
            Unable to load Athena appointments. Currently displaying Scheduler appointments & Google
            Calendar events. This page will update once Athena connection has been restored.
          </div>
        </div>
      )}

      {status === ComponentStatus.Online && (
        <div
          className='flex items-center p-4 m-3 mr-3 ml-3 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800'
          role='alert'
        >
          <i className='pi pi-info-circle'></i>
          <div className='ml-2'>
            Connection to Athena has been restored. Please refresh the page.
          </div>
        </div>
      )}
    </>
  );
};
