import { env } from '@/utils/constants';
import { DateTime } from 'luxon';

function returnTodayIfPastDate(date: string): string {
  const now = DateTime.now();
  if (DateTime.fromFormat(date, 'MM-dd-yyyy').startOf('day') < now.startOf('day')) {
    return returnOnlyDateMMddYYYY(new Date());
  }
  return date;
}

function returnOnlyDateMMddYYYY(date: Date): string {
  const formattedDate = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-${
    date.getDate() < 10 ? '0' : ''
  }${date.getDate()}-${date.getFullYear()}`;
  return formattedDate;
}

function returnOnlyDateYYYYmmDD(date: Date): string {
  const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' : ''}${
    date.getMonth() + 1
  }-${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
  return formattedDate;
}
function changeFormatmmDDYYYYToYYYYDDmm(date: string, divisor = '-'): string {
  const dateSplitted = date.split(divisor);

  const month = dateSplitted[0].length === 1 ? `0${dateSplitted[0]}` : dateSplitted[0];
  const day = dateSplitted[1].length === 1 ? `0${dateSplitted[1]}` : dateSplitted[1];

  const formattedDate = `${dateSplitted[2]}-${month}-${day}`;
  return formattedDate;
}

const formatDateToUseInCalendar = (date?: string | Date) => {
  if (date instanceof Date) {
    return date;
  }
  if (date?.includes('T')) {
    return DateTime.fromFormat(date?.split('T')[0] || '', 'yyyy-MM-dd').toJSDate();
  }
  if (date?.includes(',')) {
    return env.APP_FEATURE_FLAGS.IS_TO_USE_FIXED_TIME_AND_TIMEZONE_ON_DATE_PICKER_CHANGE
      ? new Date(
          DateTimeHelper.changeFormatmmDDYYYYToYYYYDDmm(date.split(',')[0], '/') + 'T04:04:00-04:00'
        )
      : DateTime.fromFormat(date?.split(',')[0] || '', 'M/d/yyyy').toJSDate();
  }
};

/**
 * Combines the start date and time of an appointment, handling multiple date formats.
 * @param appointmentDate - The date of the appointment.
 * @param appointmentStartTime - The start time of the appointment.
 * @returns {DateTime} The formatted date-time object.
 */
function returnDateTimeForAppointmentStartTime(
  appointmentDate: string | Date,
  appointmentStartTime: string
) {
  if (appointmentDate instanceof Date) {
    const [hours, minutes] = appointmentStartTime.split(':');
    return DateTime.fromJSDate(appointmentDate).set({
      hour: parseInt(hours, 10),
      minute: parseInt(minutes, 10),
    });
  }

  if (appointmentDate.includes(',')) {
    // Handles format like "6/10/2024, 7:45:00 AM"
    const datePart = appointmentDate.split(',')[0];
    const timePart = appointmentStartTime;
    return DateTime.fromFormat(`${datePart} ${timePart}`, 'M/d/yy T');
  }

  // Handles ISO format like "2024-06-10T12:30:00.000Z"
  return DateTime.fromISO(appointmentStartTime);
}

export const DateTimeHelper = {
  returnTodayIfPastDate,
  returnOnlyDateMMddYYYY,
  returnOnlyDateYYYYmmDD,
  changeFormatmmDDYYYYToYYYYDDmm,
  formatDateToUseInCalendar,
  returnDateTimeForAppointmentStartTime,
};
