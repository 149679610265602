import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { AppointmentWithDate, Atria, Origin, isPatientVisit } from '@/@types';
import { ChangeEvent } from '@/components';
import { useToastContext } from '@/contexts';
import { appointmentsService } from '@/services';

export function useSchedulerResize() {
  const { toast } = useToastContext();

  const handleResizableAccessor = useCallback((appointment: AppointmentWithDate) => {
    return appointment.origin === Origin.Atria;
  }, []);

  const getDurationTime = useCallback((start: Date, end: Date) => {
    const startedFormatted = DateTime.fromJSDate(start);
    const endFormatted = DateTime.fromJSDate(end);
    const { minutes: duration } = endFormatted.diff(startedFormatted, 'minutes');
    return duration;
  }, []);

  const updateAppointmentByResizing = useCallback(
    async (event: ChangeEvent, onUpdate: (appointment: Atria.Appointment) => void) => {
      const { start, end, event: appointment } = event;

      if (isPatientVisit(appointment)) return;
      if (appointment.origin !== Origin.Atria) return;
      const date = start.toISOString();
      const duration = getDurationTime(start, end);
      if (duration < 15) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Appointment must be greater than 15 minutes',
          life: 3000,
        });
        return;
      }
      await appointmentsService.updateAppointment(appointment.id, {
        title: appointment.title!,
        atriaAppointment: appointment.atriaAppointment,
        rooms: (appointment?.rooms || []).map(({ id }) => ({ id })),
        date,
        duration,
        providerId: appointment.providerId,
        providerName: appointment.providerName,
      });
      toast?.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Appointment successfully updated',
        life: 3000,
      });
      onUpdate({
        ...appointment,
        duration,
        date,
        end: end.toISOString(),
        patientName: appointment?.patientName || undefined,
        rooms: (appointment?.rooms || []).map((r) => ({ id: r.id, name: r.label })),
        providers: (appointment?.providers || []).map((p) => ({
          id: p.id,
          name: p.name,
          type: p.type as Atria.AppointmentsProviders,
        })),
      });
    },
    [getDurationTime, toast]
  );

  return {
    handleResizableAccessor,
    updateAppointmentByResizing,
  };
}
