import { AppointmentEventType, AppointmentWithDate, PatientVisit } from '@/@types';
import { DEFAULT_GOOGLE_EVENT_TITLE } from '@/helpers';
import { DateTime } from 'luxon';

const groupAppointmentsByDay = (
  appointments: (AppointmentWithDate & AppointmentEventType)[]
): Map<number, (AppointmentWithDate & AppointmentEventType)[]> => {
  return appointments.reduce((map, appointment) => {
    const apptDay = appointment.date.getDay();

    if (!map.has(apptDay)) {
      map.set(apptDay, []);
    }

    map.get(apptDay)!.push(appointment);
    return map;
  }, new Map<number, (AppointmentWithDate & AppointmentEventType)[]>());
};

const getMinStartDate = (appointments: (AppointmentWithDate & AppointmentEventType)[]): Date => {
  return appointments.reduce(
    (min, curr) => (curr.date < min ? curr.date : min),
    appointments[0].date
  );
};

const getMaxEndDate = (appointments: (AppointmentWithDate & AppointmentEventType)[]): Date => {
  return appointments.reduce((max, curr) => (curr.end > max ? curr.end : max), appointments[0].end);
};

const createPatientVisit = (
  providerId: string,
  minStartDate: Date,
  maxEndDate: Date,
  dayAppointments: (AppointmentWithDate & AppointmentEventType)[]
): PatientVisit => {
  return {
    title: DEFAULT_GOOGLE_EVENT_TITLE,
    date: DateTime.fromJSDate(minStartDate).minus({ minutes: 15 }).toJSDate(),
    end: DateTime.fromJSDate(maxEndDate).toJSDate(),
    resourceId: `provider-${providerId}`,
    isVisitEvent: true,
    appointments: dayAppointments.map((appt) => ({
      ...appt,
      resourceId: `provider-${providerId}`,
    })),
    liaison: undefined,
  };
};

export const PatientVisitHelper = {
  groupAppointmentsByDay,
  getMinStartDate,
  getMaxEndDate,
  createPatientVisit,
};
