import { AppointmentEventType, AppointmentParsed } from '@/@types';
import { ExtendButton } from '../ExtendButton';
import MobileDetect from 'mobile-detect';

export const CustomResourceHeader = ({
  resourceId,
  label,
  index,
  isAllDay,
  isExtendedColumn,
  handleColumnSizing,
  events,
  outOfOfficeEventId,
}: {
  resourceId: string;
  label: string;
  index: number;
  isAllDay: boolean;
  isExtendedColumn: boolean;
  handleColumnSizing: (index: string | number, decreaseColumn?: boolean) => void;
  events: (AppointmentParsed & {
    resourceId?: string;
  } & AppointmentEventType)[];
  outOfOfficeEventId: number | undefined;
}) => {
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  const isMobile = mobileDetect.mobile();
  const isPodOrProvider = resourceId?.includes('cmo') || resourceId?.includes('provider');

  if (isAllDay) {
    return (
      <div className='flex flex-col gap-1 mt-2'>
        {!isMobile && (
          <ExtendButton
            index={index}
            isPodOrProvider={isPodOrProvider}
            isExtendedColumn={isExtendedColumn}
            handleColumnSizing={handleColumnSizing}
          />
        )}

        <div className='flex md:flex-col flex-wrap justify-center gap-3 md:gap-1'>
          <div>
            <div>{label}</div>

            <div className='flex flex-wrap justify-center items-center gap-3'>
              {events
                ?.filter((e) => e.isAllDay && (!e.isOutOfOffice || e.id === outOfOfficeEventId))
                .map((event, eventIndex) => (
                  <div
                    className='flex items-center justify-center'
                    key={`all-day-event-` + eventIndex}
                  >
                    {event?.workingLocationType && (
                      <div>
                        <div className="text-white text-[10px] font-normal font-['Inter'] bg-[#869D89] rounded-[10px] px-2 py-[3px]">
                          {event.title.replace('(Office)', '')}
                        </div>
                      </div>
                    )}

                    {event.isOutOfOffice && event.id === outOfOfficeEventId && (
                      <div className='text-[#F00] font-bold text-xs'>Out of Office</div>
                    )}

                    {!event?.workingLocationType && !event.isOutOfOffice && (
                      <div className='text-[#757575] font-normal text-xs'>{event.title}</div>
                    )}
                  </div>
                ))}
            </div>
          </div>

          {isMobile && (
            <ExtendButton
              index={index}
              isPodOrProvider={isPodOrProvider}
              isExtendedColumn={isExtendedColumn}
              handleColumnSizing={handleColumnSizing}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-1 mt-2'>
      {!isMobile && (
        <ExtendButton
          index={index}
          isPodOrProvider={isPodOrProvider}
          isExtendedColumn={isExtendedColumn}
          handleColumnSizing={handleColumnSizing}
        />
      )}

      <div className='custom-resource-header flex items-end flex-col gap-6'>
        <div className='flex md:unset gap-3 self-center'>
          <h1 className='border-b border-[#ddd]'>{label}</h1>

          {isMobile && (
            <ExtendButton
              index={index}
              isPodOrProvider={isPodOrProvider}
              isExtendedColumn={isExtendedColumn}
              handleColumnSizing={handleColumnSizing}
            />
          )}
        </div>
      </div>
    </div>
  );
};
