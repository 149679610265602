import { useCallback } from 'react';
import { useLocalStorage } from '@/hooks';

type WeeklyCalendarSettings = {
  showFullCalendar: boolean;
  showAthenaAppointments: boolean;
  showGoogleCalendarEvents: boolean;
  showUnconfirmedAppointments: boolean;
  showPatientBackgroundEvents: boolean;
  showWeekends: boolean;
};

export const useWeeklyCalendarSettings = () => {
  const [weeklyCalendarSettings, setWeeklyCalendarSettings] =
    useLocalStorage<WeeklyCalendarSettings>('WeeklyCalendarSettings', {
      showFullCalendar: false,
      showAthenaAppointments: false,
      showGoogleCalendarEvents: false,
      showUnconfirmedAppointments: true,
      showPatientBackgroundEvents: true,
      showWeekends: false,
    });

  const setSettingsItem = useCallback(
    (key: keyof WeeklyCalendarSettings, value: boolean) => {
      return setWeeklyCalendarSettings((prev) => ({ ...prev, [key]: value }));
    },
    [setWeeklyCalendarSettings]
  );

  const onShowFullCalendar = useCallback(
    (checked: boolean) => {
      setSettingsItem('showFullCalendar', checked);
    },
    [setSettingsItem]
  );

  const onShowAthenaAppointments = useCallback(
    (checked: boolean) => {
      setSettingsItem('showAthenaAppointments', checked);
    },
    [setSettingsItem]
  );

  const onShowGoogleCalendarEvents = useCallback(
    (checked: boolean) => {
      setSettingsItem('showGoogleCalendarEvents', checked);
    },
    [setSettingsItem]
  );

  const onShowUnconfirmedAppointments = useCallback(
    (checked: boolean) => {
      setSettingsItem('showUnconfirmedAppointments', checked);
    },
    [setSettingsItem]
  );

  const onShowPatientBackgroundEvents = useCallback(
    (checked: boolean) => {
      setSettingsItem('showPatientBackgroundEvents', checked);
    },
    [setSettingsItem]
  );

  const onShowWeekends = useCallback(
    (checked: boolean) => {
      setSettingsItem('showWeekends', checked);
    },
    [setSettingsItem]
  );

  return {
    ...weeklyCalendarSettings,
    onShowFullCalendar,
    onShowAthenaAppointments,
    onShowGoogleCalendarEvents,
    onShowUnconfirmedAppointments,
    onShowPatientBackgroundEvents,
    onShowWeekends,
  };
};
