import { AppointmentWithResourcesList, Atria } from '@/@types';
import { mountAppointmentObject } from './mountAppointmentObject';

const homeAthenaDepartment = [
  Atria.AthenaDepartment.NY_HOME_SERVICES,
  Atria.AthenaDepartment.PB_HOME_SERVICES,
];

const otherAthenaDepartment = [
  Atria.AthenaDepartment.NY_INSTITUTE,
  Atria.AthenaDepartment.PB_INSTITUTE,
  Atria.AthenaDepartment.NY_PEDIATRICS,
  Atria.AthenaDepartment.PB_PEDIATRICS,
];

const nyAthenaDepartment = [
  Atria.AthenaDepartment.NY_INSTITUTE,
  Atria.AthenaDepartment.NY_HOME_SERVICES,
  Atria.AthenaDepartment.NY_PEDIATRICS,
];

const enum HomeVisitRooms {
  NY = 16,
  PB = 31,
}

export function parseAppointments(
  appointments: Atria.Appointment[]
): AppointmentWithResourcesList[] {
  const results: AppointmentWithResourcesList[] = [];

  for (const appointment of appointments) {
    const item = mountAppointmentObject(appointment) as AppointmentWithResourcesList;
    item.resourceId = [];

    if (appointment.providerId) {
      item.resourceId.push(`provider-${appointment.providerId}`);
    }

    const athenaDepartmentId = appointment.athenaDepartmentId as Atria.AthenaDepartment;
    const rooms = [];
    if (shouldAddHomeVisitRoom(item, athenaDepartmentId)) {
      rooms.push({
        id: isNyHomeVisit(athenaDepartmentId) ? HomeVisitRooms.NY : HomeVisitRooms.PB,
      });
    }

    rooms.push(...(item.rooms || []));

    for (const { id } of rooms) {
      item.resourceId.push(`rooms-${id}`);
    }

    if (item.providers && item.providers.length > 0) {
      for (const { id, type } of item.providers) {
        if (type === 'EQUIPMENT') {
          item.resourceId.push(`equipment-${id}`);
        } else {
          item.resourceId.push(`provider-${id}`);
        }
      }
    }

    if (item.patientPrimaryProviderId) {
      item.resourceId.push(`cmo-${appointment.patientPrimaryProviderId}`);
    }

    if (appointment.patientId) {
      item.resourceId.push(`patient-${appointment.patientId}`);
    }

    results.push(item);
  }
  return results;
}

function shouldAddHomeVisitRoom(
  appointment: AppointmentWithResourcesList,
  athenaDepartmentId: number
) {
  const isHomeAthenaDepartment = homeAthenaDepartment.includes(athenaDepartmentId);
  const isHomeVisitTypeInOtherDepartment =
    otherAthenaDepartment.includes(athenaDepartmentId) &&
    appointment.type?.toLowerCase().includes('home');

  if (appointment.rooms?.length || isHomeAthenaDepartment || isHomeVisitTypeInOtherDepartment) {
    const homeVisitRoomId = isNyHomeVisit(athenaDepartmentId)
      ? HomeVisitRooms.NY
      : HomeVisitRooms.PB;

    const isHomeVisitNotMappedYet = !appointment.rooms?.find((room) => room.id === homeVisitRoomId);
    const isToAddAppointmentToHomeVisitRoom =
      isHomeVisitNotMappedYet && (isHomeAthenaDepartment || isHomeVisitTypeInOtherDepartment);

    return isToAddAppointmentToHomeVisitRoom;
  }

  return false;
}

function isNyHomeVisit(athenaDepartmentId: number) {
  return nyAthenaDepartment.includes(athenaDepartmentId);
}
