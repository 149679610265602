import classNames from 'classnames';
import { AppointmentWithResourcesList, Origin } from '@/@types';
import { env } from '@/utils/constants';

export const APPOINTMENT_TYPES_CLASSES = {
  medicationAdminInfusion: [821],
  performanceMovement: [283, 364, 282, 369, 284, 365, 221, 366, 367, 303, 368],
  cardiology: [142, 143, 144, 5, 6, 321, 953],
  nutrition: [561, 562, 641, 642],
  neuro: [188, 185, 464, 201, 202, 201, 461, 462, 186, 463, 187, 182, 341, 181, 184, 861],
  endocrinology: [581, 601, 602, 603],
  gynecology: [21, 22, 23, 741, 742, 841, 842, 843, 844, 845, 846, 847, 848],
  mri: [122, 941, 942],
  ultrasound: [127],
  ct: [121],
  dexa: [123],
  xray: [125],
  mammo: [124],
  integratMed: [721, 722, 723, 724, 725, 726, 727, 728, 943, 944],
  memberLunch: [130],
  steamCellProgram: [501],
};

export const getPatientVisitClasses = () => ({
  className: 'event-wrapper rbc-background-event bg-zinc',
});

export const getSelectedEventClasses = () => 'bg-gray-200 text-[var(--kelp)]';

export const getAppointmentCardClasses = (appointment: AppointmentWithResourcesList) => {
  return {
    className: classNames('event-wrapper', {
      conflictEvent:
        env.APP_FEATURE_FLAGS.IS_TO_SHOW_APPOINTMENTS_CONFLICTS && appointment.hasConflict,
      external: appointment.externalClient,
      ...([Origin.Atria].includes(appointment.origin) && {
        appointmentConfirmed: appointment.confirmed,
        appointmentNotConfirmed: !appointment.confirmed,
      }),
      ...(appointment.typeId && {
        performanceMovementEvent: APPOINTMENT_TYPES_CLASSES.performanceMovement.includes(
          appointment.typeId
        ),
        cardiologyEvent: APPOINTMENT_TYPES_CLASSES.cardiology.includes(appointment.typeId),
        nutritionEvent: APPOINTMENT_TYPES_CLASSES.nutrition.includes(appointment.typeId),
        neuroEvent: APPOINTMENT_TYPES_CLASSES.neuro.includes(appointment.typeId),
        endocrinologyEvent: APPOINTMENT_TYPES_CLASSES.endocrinology.includes(appointment.typeId),
        gynecologyEvent: APPOINTMENT_TYPES_CLASSES.gynecology.includes(appointment.typeId),
        mriEvent: APPOINTMENT_TYPES_CLASSES.mri.includes(appointment.typeId),
        ultrasoundEvent: APPOINTMENT_TYPES_CLASSES.ultrasound.includes(appointment.typeId),
        ctEvent: APPOINTMENT_TYPES_CLASSES.ct.includes(appointment.typeId),
        dexaEvent: APPOINTMENT_TYPES_CLASSES.dexa.includes(appointment.typeId),
        xrayEvent: APPOINTMENT_TYPES_CLASSES.xray.includes(appointment.typeId),
        mammoEvent: APPOINTMENT_TYPES_CLASSES.mammo.includes(appointment.typeId),
        integratMed: APPOINTMENT_TYPES_CLASSES.integratMed.includes(appointment.typeId),
        memberLunch: APPOINTMENT_TYPES_CLASSES.memberLunch.includes(appointment.typeId),
        steamCellProgram: APPOINTMENT_TYPES_CLASSES.steamCellProgram.includes(appointment.typeId),
      }),
      googleEvent: appointment.origin === Origin.Google,
      athenaEvent: appointment.origin === Origin.Athena,
    }),
  };
};
