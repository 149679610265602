import { createContext, useCallback, useContext, useState } from 'react';

import { useLocalStorage } from './useLocalStorage';

export type SchedulerViewSettingsOptions = {
  hidePatientName: boolean;
  showFullCalendar: boolean;
  showAthenaAppointments: boolean;
  showAtriaAppointments: boolean;
  showGoogleCalendarEvents: boolean;
  showUnconfirmedAppointments: boolean;
  showPatientBackgroundEvents: boolean;
  showEmptyRooms: boolean;
};

type Type = {
  showCalendarViewSettings: boolean;
  setShowCalendarViewSettings: (show: boolean) => void;
  hidePatientName: boolean;
  showFullCalendar: boolean;
  showAthenaAppointments: boolean;
  showAtriaAppointments: boolean;
  showGoogleCalendarEvents: boolean;
  showUnconfirmedAppointments: boolean;
  showPatientBackgroundEvents: boolean;
  showEmptyRooms: boolean;
  onShowFullCalendar: (checked: boolean) => void;
  onHidePatientName: (checked: boolean) => void;
  onShowGoogleCalendarEvents: (checked: boolean) => void;
  onShowAthenaAppointments: (checked: boolean) => void;
  onShowAtriaAppointments: (checked: boolean) => void;
  onShowPatientBackgroundEvents: (checked: boolean) => void;
  onShowUnconfirmedAppointments: (checked: boolean) => void;
  onShowEmptyRooms: (checked: boolean) => void;
};

const Context = createContext<Type>({
  showCalendarViewSettings: false,
  setShowCalendarViewSettings: () => {},
  onShowFullCalendar: () => {},
  onHidePatientName: () => {},
  onShowGoogleCalendarEvents: () => {},
  onShowAthenaAppointments: () => {},
  onShowAtriaAppointments: () => {},
  onShowPatientBackgroundEvents: () => {},
  onShowUnconfirmedAppointments: () => {},
  onShowEmptyRooms: () => {},
  hidePatientName: false,
  showFullCalendar: false,
  showAthenaAppointments: true,
  showAtriaAppointments: true,
  showGoogleCalendarEvents: true,
  showUnconfirmedAppointments: true,
  showPatientBackgroundEvents: true,
  showEmptyRooms: true,
});

type Props = {
  children?: React.ReactNode;
};

export function SchedulerViewSettingsContext({ children }: Props) {
  const [showCalendarViewSettings, setShowCalendarViewSettings] = useState(false);
  const [filters, setFilter] = useLocalStorage<SchedulerViewSettingsOptions>('schedulerOptions', {
    hidePatientName: false,
    showFullCalendar: false,
    showAthenaAppointments: true,
    showAtriaAppointments: true,
    showGoogleCalendarEvents: true,
    showUnconfirmedAppointments: true,
    showPatientBackgroundEvents: true,
    showEmptyRooms: true,
  });
  const setFilterItem = useCallback(
    (key: keyof SchedulerViewSettingsOptions, value: boolean) => {
      return setFilter((prev) => ({ ...prev, [key]: value }));
    },
    [setFilter]
  );

  const onShowFullCalendar = useCallback(
    (checked: boolean) => {
      setFilterItem('showFullCalendar', checked);
    },
    [setFilterItem]
  );

  const onHidePatientName = useCallback(
    (checked: boolean) => {
      setFilterItem('hidePatientName', checked);
    },
    [setFilterItem]
  );

  const onShowGoogleCalendarEvents = useCallback(
    (checked: boolean) => {
      setFilterItem('showGoogleCalendarEvents', checked);
    },
    [setFilterItem]
  );

  const onShowAthenaAppointments = useCallback(
    (checked: boolean) => {
      setFilterItem('showAthenaAppointments', checked);
    },
    [setFilterItem]
  );

  const onShowAtriaAppointments = useCallback(
    (checked: boolean) => {
      setFilterItem('showAtriaAppointments', checked);
    },
    [setFilterItem]
  );

  const onShowPatientBackgroundEvents = useCallback(
    (checked: boolean) => {
      setFilterItem('showPatientBackgroundEvents', checked);
    },
    [setFilterItem]
  );

  const onShowUnconfirmedAppointments = useCallback(
    (checked: boolean) => {
      setFilterItem('showUnconfirmedAppointments', checked);
    },
    [setFilterItem]
  );

  const onShowEmptyRooms = useCallback(
    (checked: boolean) => {
      setFilterItem('showEmptyRooms', checked);
    },
    [setFilterItem]
  );

  return (
    <Context.Provider
      value={{
        ...filters,
        onShowFullCalendar,
        onHidePatientName,
        onShowGoogleCalendarEvents,
        onShowAthenaAppointments,
        onShowAtriaAppointments,
        onShowPatientBackgroundEvents,
        onShowUnconfirmedAppointments,
        onShowEmptyRooms,
        showCalendarViewSettings,
        setShowCalendarViewSettings,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useSchedulerViewSettings = () => useContext(Context);
