import { HttpStatusCode } from 'axios';
import { createContext, useCallback, useContext, useState } from 'react';
import { useToastContext } from '@/contexts';
import { dailyNotesService } from '@/services';
import { DateTimeHelper } from '@/helpers';
import { DailyNotes } from '@/@types';

type Type = {
  findDailyNotesByDate: (date: Date, locationId: number) => Promise<DailyNotes[]>;
  createDailyNotes: (
    date: Date,
    title: string,
    html: string,
    locationId: number
  ) => Promise<DailyNotes | null>;
  updateDailyNote: (id: number, title: string, html: string) => Promise<DailyNotes | null>;
  deleteDailyNote: (id: number) => Promise<boolean>;
  showEditDailyNotes: boolean;
  setShowEditDailyNotes: (showEditDailyNotes: boolean) => void;
};

const Context = createContext<Type>({
  findDailyNotesByDate: async () => [],
  createDailyNotes: async () => null,
  updateDailyNote: async () => null,
  deleteDailyNote: async () => false,
  showEditDailyNotes: false,
  setShowEditDailyNotes: () => {},
});

type Props = {
  children?: React.ReactNode;
};

export function DailyNotesContext({ children }: Props) {
  const { toast: ref } = useToastContext();
  const [showEditDailyNotes, setShowEditDailyNotes] = useState(false);

  const findDailyNotesByDate = useCallback(
    async (date: Date, locationId: number): Promise<DailyNotes[]> => {
      try {
        const { data } = await dailyNotesService.findDailyNotesByDate(
          DateTimeHelper.returnOnlyDateYYYYmmDD(date),
          locationId
        );

        return data;
      } catch {
        return [];
      }
    },
    []
  );

  const createDailyNotes = useCallback(
    async (date: Date, title: string, html: string, locationId: number) => {
      try {
        const { status, data } = await dailyNotesService.createDailyNotes(
          DateTimeHelper.returnOnlyDateYYYYmmDD(date),
          title,
          html,
          locationId
        );

        if (status !== HttpStatusCode.Ok) {
          return null;
        }

        ref?.current.show({
          severity: 'success',
          summary: 'Saved',
          detail: 'Daily note saved successfully',
          life: 1500,
        });

        return data;
      } catch {
        return null;
      }
    },
    [ref]
  );

  const updateDailyNote = useCallback(
    async (id: number, title: string, html: string) => {
      try {
        const { status, data } = await dailyNotesService.updateDailyNote(id, title, html);

        if (status !== HttpStatusCode.Ok) {
          return null;
        }

        ref?.current.show({
          severity: 'success',
          summary: 'Saved',
          detail: 'Daily note saved successfully',
          life: 1500,
        });

        return data;
      } catch {
        return null;
      }
    },
    [ref]
  );

  const deleteDailyNote = useCallback(
    async (id: number) => {
      try {
        const { status } = await dailyNotesService.deleteDailyNote(id);

        if (status !== HttpStatusCode.NoContent) {
          return false;
        }

        ref?.current.show({
          severity: 'success',
          summary: 'Deleted',
          detail: 'Daily note deleted successfully',
          life: 1500,
        });

        return true;
      } catch {
        return false;
      }
    },
    [ref]
  );

  return (
    <Context.Provider
      value={{
        showEditDailyNotes,
        findDailyNotesByDate,
        createDailyNotes,
        updateDailyNote,
        deleteDailyNote,
        setShowEditDailyNotes,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useDailyNotes = () => useContext(Context);
