import { Cmo, Personnel } from '@/@types';
import { useSchedulerSavedViewContext } from '@/features/Calendar/contexts/schedulerSavedViewContext';
import { ResourceHelper } from '@/helpers';
import { useLocalStorage, useProviders } from '@/hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';

export type WeeklyFiltersProps = {
  personnel: Personnel[];
  cliniciansList: Personnel[];
  isViewingDefaultView: boolean;
  cmos: Cmo[];
  podsList: Cmo[];
  stateInitialized: boolean;
};

export type OnWeeklyFiltersChange = {
  key: keyof Pick<WeeklyFiltersProps, 'personnel' | 'cmos'>;
  value: Personnel[] | Cmo[];
};

const initialFiltersValuesFromLocalStorage: WeeklyFiltersProps = {
  personnel: [],
  cliniciansList: [],
  isViewingDefaultView: true,
  cmos: [],
  podsList: [],
  stateInitialized: false,
};

export const useWeeklyCalendarFilters = () => {
  const { providersList: cliniciansList, cmosFilterList: podsList } = useProviders();
  const { selectedView, selectCurrentSchedulerView } = useSchedulerSavedViewContext();

  const [filters, setFilters] = useLocalStorage<WeeklyFiltersProps>('weeklyFilters', {
    ...initialFiltersValuesFromLocalStorage,
    cliniciansList,
    podsList,
  });
  const filterRef = useRef<WeeklyFiltersProps>(filters);
  filterRef.current = filters;

  const isViewingDefaultView = useMemo(() => {
    return filters.personnel.length === 0 && filters.cmos?.length === 0;
  }, [filters]);

  const setFilterItem = useCallback(
    (key: keyof WeeklyFiltersProps, value: any) => {
      setFilters((prev) => ({ ...prev, stateInitialized: true, [key]: value }));
    },
    [setFilters]
  );

  useEffect(() => {
    if (selectedView) {
      setFilters((previous) => ({
        ...previous,
        personnel: ResourceHelper.filterResourceList(
          cliniciansList,
          selectedView.filters?.personnel
        ),
        cmos: ResourceHelper.filterResourceList(podsList, selectedView.filters?.cmos),
        stateInitialized: true,
      }));
      return;
    }

    setFilters((previous) => ({
      ...previous,
      stateInitialized: true,
    }));
  }, [cliniciansList, podsList, selectedView, setFilters]);

  useEffect(() => {
    if (selectedView) {
      setTimeout(() => {
        const cmosListTemp = filterRef.current.cmos.map(({ resourceId }) =>
          Number(resourceId.split('-')[1])
        );
        const personnelListTemp = filterRef.current.personnel.map(({ resourceId }) =>
          Number(resourceId.split('-')[1])
        );

        const isFilterStateEqualToSavedViewState =
          cmosListTemp.toString() === selectedView.filters?.cmos?.toString() &&
          personnelListTemp.toString() === selectedView.filters?.personnel?.toString();
        if (!isFilterStateEqualToSavedViewState) {
          selectCurrentSchedulerView(null);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView, filters]);

  return {
    filters,
    isViewingDefaultView,
    setFilters,
    setFilterItem,
  };
};
